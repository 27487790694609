import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Cookies from 'universal-cookie'

import { exclusive, translated } from '../constants/data/exclusive'

import useIsExclusive from '../hooks/useIsExclusive'

import { TitleXS, BodyS } from '../styles/Typography'
import { LinkAsButtonGatsbyPrimary } from '../styles/components/LinkStyled'
import { ModalStyled, ModalOverlay, ModalContent } from '../styles/components/ModalStyled'

/**
 * The modal for exclusive countries.
 * @param {ComponentProps} props
 * @param {String} props.pathname
 * @returns {FunctionComponent}
 */
export const ExclusiveDistributorModal = ({ pathname }) => {
  const [isOpen, setIsOpen] = useState(false)

  const { isExclusive, isTranslated, countryCode } = useIsExclusive()

  const cookies = new Cookies()

  useEffect(() => {
    const isCookieSet = cookies.get('isExclusive')

    const isCurrentPathTranslated = pathname.includes(countryCode.toLowerCase())

    if ((isExclusive || isTranslated) && !isCurrentPathTranslated && !isCookieSet) {
      setIsOpen(true)
    }
  })

  const handleClick = () => {
    cookies.set('isExclusive', 'true', { maxAge: 3600 })
    setIsOpen(false)
  }

  const translatedAndExclusive = { ...translated, ...exclusive }

  return (
    <ModalStyled isOpen={isOpen} zIndex={1000}>
      <ModalOverlay onClick={() => setIsOpen(false)} />
      <ModalContent maxWidth="600px">
        <TitleXS className="title">{translatedAndExclusive[countryCode]?.title}</TitleXS>
        <LinkAsButtonGatsbyPrimary className="btn" to={translatedAndExclusive[countryCode]?.link ?? '/'} onClick={handleClick}>
          {translatedAndExclusive[countryCode]?.btn_redirect}
        </LinkAsButtonGatsbyPrimary>
        <BodyS className="link" onClick={handleClick}>
          {translatedAndExclusive[countryCode]?.btn_close}
        </BodyS>
      </ModalContent>
    </ModalStyled>
  )
}

ExclusiveDistributorModal.propTypes = {
  pathname: PropTypes.string.isRequired,
}

export default ExclusiveDistributorModal
